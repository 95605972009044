@import "vars2";

html {
  scroll-behavior: smooth;
}

body {
	position: relative;
	width: 100vw;
	background: #fff;
	color: #000;
	font-family: var(--font-family);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.25;
	text-rendering: optimizeSpeed;
	word-wrap: break-word;
	overflow-wrap: break-word;
	text-size-adjust: none;
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	overflow-y: auto;

	// Firefox Scroll
	scrollbar-width: thin;          /* "auto" or "thin"  */
	scrollbar-color: #f5f5f5 var(--accent-color);

	// webkit scroll
	&::-webkit-scrollbar-track {
    background-color: #f5f5f5;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--accent-color);
	}
}


*::selection {
	background-color: #fafafa;
	color: #000;
}


.container {
	width: 100%;
	max-width: var(--container-width);
	padding: 0 var(--container-offset);
	margin: auto;

	@media (max-width: $tablet) {
		padding: 0 $tablet-padding;
	}

	@media (max-width: 700px) {
		padding: 0 $mob-padding;
	}
}

.site-container {
  overflow: hidden;
}

._shine {
	position: relative;
	overflow: hidden;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: -70px;
		height: 100%;
		width: 50px;
		background: rgba(255, 255, 255, 0.4);
		transition: all .3s linear;
		transform: skewX(30deg) translateX(0);
	}

	&:hover:before {
		transform: skewX(30deg) translateX(300px);
	}
}