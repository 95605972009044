@import "vars2";

.hero {
  position: relative;
  display: grid;
  grid-template-areas: 'hero';
  
  & > * {
    grid-area: hero;
  } 
  
  .tns-outer > button {
    display: none;
    visibility: hidden;
    position: absolute;
  } 

  #tns1 > .tns-item {
    transition-duration: .5s;
    animation-duration: .5s;
  }


  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 25, 52, 0.15);
    z-index: 1;
  }

  &__img {
    width: 100%;
    height: 100%;
    max-height: 1000px;
    object-fit: cover;
    object-position: center;

    @media (max-width: $tablet) {
      min-height: 100vh;
      max-height: 900px;
    }
  }

  .container {
    position: relative;
    z-index: 2;
    padding-left: 46px;

    @media (max-width: $tablet) {
      padding-left: 0;
      text-align: center;
    }
  }

  &__badge {
    display: table;
    margin-top: -70px;
    margin-bottom: 30px;
    padding: 4px 20px;
    color: var(--text-color);
    background: #fff;
    font-size: 20px;
    line-height: 24px;
    font-weight: 900;
    border-radius: 8px;

    @media (max-width: $laptop) {
      margin-top: 0;
    }

    @media (max-width: $tablet) {
      margin: 0 auto 20px auto;
    }

    @media (max-width: $mobile) {
      
    }
  }

  &__title {
    padding-bottom: 16px;
    font-size: 64px;
    color: #fff;
    font-weight: 900;

    @media (max-width: $tablet) {
      font-size: 40px;
    }

    @media (max-width: $mobile) {
      
    }
  }

  &__subtitle {
    padding-bottom: 30px;
    font-weight: 900;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.8);

    @media (max-width: $tablet) {
      padding-bottom: 32px;
    }

    @media (max-width: $mobile) {
      
    }
  }

  &__decor {
    position: absolute;
    right: 0;
    height: 100%;
    pointer-events: none;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  .player {
    display: flex;
    align-items: center;

    @media (max-width: $tablet) {
      justify-content: center;
    }

    &__btn {
      border: 0;
      background: transparent;
      outline: none;
      cursor: pointer;

      svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: #fff;
        transition: fill var(--fast);
      }
  
      &:hover {
        svg {
          fill: var(--accent-color);
        }
      }
    }

    &__btn-play {
      width: 120px;
      height: 120px;

      @media (max-width: $tablet) {
        width: 110px;
        height: 110px;
      }
    }

    &__btn-sound {
      width: 24px;
      height: 24px;

      &.active {
        fill: var(--accent-color);
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__control {
      margin-left: 15px;
      display: flex;
      
      @media (max-width: $tablet) {
        display: none;
      }
    }

    &__range {
      margin: 0 15px;

      input {
          background: linear-gradient(to right, #fff 0%, #fff 88%, rgba(27, 25, 52, 0.8) 88%, rgba(27, 25, 52, 0.8) 100%);
          border: 0;
          height: 2px;
          width: 235px;
          outline: none;
          transition: background var(--fast);
          -webkit-appearance: none;
          cursor: pointer;
        
        &::-webkit-slider-thumb {
          width: 20px;
          height: 20px;
          -webkit-appearance: none;
          background: transparent;
          visibility: hidden;
        }

        &::-moz-range-thumb {
          width: 20px;
          height: 20px;
          -webkit-appearance: none;
          background: transparent;
          visibility: hidden;
        }
      }
    }
  }
}