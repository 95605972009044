:root {
  // base
  --font-family: 'Montserrat', sans-serif;
  --content-width: 1200px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fff;
  --accent-color: #FFCC66;
  --text-color: #1B1934;

  --fast: 0.3s linear;
}
