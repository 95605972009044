:root {
  --font-family: "Montserrat", sans-serif;
  --content-width: 1200px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fff;
  --accent-color: #FFCC66;
  --text-color: #1B1934;
  --fast: 0.3s linear;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */
html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  width: 100vw;
  background: #fff;
  color: #000;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  text-rendering: optimizeSpeed;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-size-adjust: none;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin; /* "auto" or "thin"  */
  scrollbar-color: #f5f5f5 var(--accent-color);
}
body::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar {
  width: 6px;
}
body::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--accent-color);
}

*::selection {
  background-color: #fafafa;
  color: #000;
}

.container {
  width: 100%;
  max-width: var(--container-width);
  padding: 0 var(--container-offset);
  margin: auto;
}
@media (max-width: 1025px) {
  .container {
    padding: 0 3rem;
  }
}
@media (max-width: 700px) {
  .container {
    padding: 0 1em;
  }
}

.site-container {
  overflow: hidden;
}

._shine {
  position: relative;
  overflow: hidden;
}
._shine:before {
  content: "";
  position: absolute;
  top: 0;
  left: -70px;
  height: 100%;
  width: 50px;
  background: rgba(255, 255, 255, 0.4);
  transition: all 0.3s linear;
  transform: skewX(30deg) translateX(0);
}
._shine:hover:before {
  transform: skewX(30deg) translateX(300px);
}

.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls],
.tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  transition: all 0s;
}

.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  transition: transform 0s, opacity 0s;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  transition: opacity 0.6s;
  opacity: 0.6;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal,
.tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div,
.tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}

.tns-t-ct {
  width: 2333.3333333%;
  width: 2333.3333333333%;
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}

.tns-t-ct > div {
  width: 1.4285714%;
  width: 1.4285714286%;
  height: 10px;
  float: left;
}

.header {
  max-width: var(--container-width);
  padding: 0 var(--container-offset);
  margin: auto;
  overflow: hidden;
}
@media (max-width: 1366px) {
  .header {
    max-width: 100%;
    width: 100%;
  }
}
.header__box {
  position: absolute;
  top: 41px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  max-width: var(--container-width);
  width: 100%;
}
@media (max-width: 1366px) {
  .header__box {
    top: 15px;
  }
}
@media (max-width: 1025px) {
  .header__box {
    left: 50%;
    transform: translateX(-50%);
    width: initial;
  }
}
@media (max-width: 767px) {
  .header__box {
    padding: 0 1em;
  }
}
.header__logo img {
  max-width: 250px;
}
@media (max-width: 767px) {
  .header__logo img {
    max-width: 189px;
  }
}
.header .nav {
  margin: 0 65px;
}
@media (max-width: 1366px) {
  .header .nav {
    margin: 0 30px;
  }
}
@media (max-width: 1025px) {
  .header .nav {
    display: none;
  }
}
.header .nav__list {
  display: flex;
}
.header .nav__item {
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  transition: color var(--fast);
}
@media (max-width: 1100px) {
  .header .nav__item {
    font-size: 13px;
  }
}
.header .nav__item:hover {
  color: var(--accent-color);
}
.header .nav__item:not(:last-of-type) {
  margin-right: 20px;
}
@media (max-width: 1366px) {
  .header .nav__item:not(:last-of-type) {
    margin-right: 10px;
  }
}
.header__donate {
  display: grid;
  place-content: center;
  height: 40px;
  min-width: 200px;
  background: var(--accent-color);
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 13px;
  font-weight: 700;
  letter-spacing: 0.0622em;
  color: var(--text-color);
  border: 1px solid #FFCC66;
  border-radius: 30px;
  cursor: pointer;
}
@media (max-width: 1025px) {
  .support-page .header__donate {
    display: none !important;
  }
}
.header__donate._mob {
  display: none;
}
@media (max-width: 1025px) {
  .header__donate._mob {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: grid;
    z-index: 9;
  }
}
@media (max-width: 1025px) {
  .header__donate._desc {
    display: none;
  }
}

.hero {
  position: relative;
  display: grid;
  grid-template-areas: "hero";
}
.hero > * {
  grid-area: hero;
}
.hero .tns-outer > button {
  display: none;
  visibility: hidden;
  position: absolute;
}
.hero #tns1 > .tns-item {
  transition-duration: 0.5s;
  animation-duration: 0.5s;
}
.hero:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 25, 52, 0.15);
  z-index: 1;
}
.hero__img {
  width: 100%;
  height: 100%;
  max-height: 1000px;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 1025px) {
  .hero__img {
    min-height: 100vh;
    max-height: 900px;
  }
}
.hero .container {
  position: relative;
  z-index: 2;
  padding-left: 46px;
}
@media (max-width: 1025px) {
  .hero .container {
    padding-left: 0;
    text-align: center;
  }
}
.hero__badge {
  display: table;
  margin-top: -70px;
  margin-bottom: 30px;
  padding: 4px 20px;
  color: var(--text-color);
  background: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 900;
  border-radius: 8px;
}
@media (max-width: 1366px) {
  .hero__badge {
    margin-top: 0;
  }
}
@media (max-width: 1025px) {
  .hero__badge {
    margin: 0 auto 20px auto;
  }
}
.hero__title {
  padding-bottom: 16px;
  font-size: 64px;
  color: #fff;
  font-weight: 900;
}
@media (max-width: 1025px) {
  .hero__title {
    font-size: 40px;
  }
}
.hero__subtitle {
  padding-bottom: 30px;
  font-weight: 900;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.8);
}
@media (max-width: 1025px) {
  .hero__subtitle {
    padding-bottom: 32px;
  }
}
.hero__decor {
  position: absolute;
  right: 0;
  height: 100%;
  pointer-events: none;
}
@media (max-width: 1025px) {
  .hero__decor {
    display: none;
  }
}
.hero .player {
  display: flex;
  align-items: center;
}
@media (max-width: 1025px) {
  .hero .player {
    justify-content: center;
  }
}
.hero .player__btn {
  border: 0;
  background: transparent;
  outline: none;
  cursor: pointer;
}
.hero .player__btn svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: #fff;
  transition: fill var(--fast);
}
.hero .player__btn:hover svg {
  fill: var(--accent-color);
}
.hero .player__btn-play {
  width: 120px;
  height: 120px;
}
@media (max-width: 1025px) {
  .hero .player__btn-play {
    width: 110px;
    height: 110px;
  }
}
.hero .player__btn-sound {
  width: 24px;
  height: 24px;
}
.hero .player__btn-sound.active {
  fill: var(--accent-color);
}
.hero .player__btn-sound svg {
  width: 24px;
  height: 24px;
}
.hero .player__control {
  margin-left: 15px;
  display: flex;
}
@media (max-width: 1025px) {
  .hero .player__control {
    display: none;
  }
}
.hero .player__range {
  margin: 0 15px;
}
.hero .player__range input {
  background: linear-gradient(to right, #fff 0%, #fff 88%, rgba(27, 25, 52, 0.8) 88%, rgba(27, 25, 52, 0.8) 100%);
  border: 0;
  height: 2px;
  width: 235px;
  outline: none;
  transition: background var(--fast);
  -webkit-appearance: none;
  cursor: pointer;
}
.hero .player__range input::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  background: transparent;
  visibility: hidden;
}
.hero .player__range input::-moz-range-thumb {
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  background: transparent;
  visibility: hidden;
}

.about {
  padding-bottom: 80px;
}
@media (max-width: 1025px) {
  .about {
    padding-bottom: 56px;
  }
}
.about__card {
  position: relative;
  margin-bottom: 60px;
  margin-top: -155px;
  padding: 52px 32px 0 32px;
  background: #fff;
  z-index: 1;
  border-radius: 8px;
}
@media (max-width: 1366px) {
  .about__card {
    margin-top: -100px;
  }
}
@media (max-width: 1025px) {
  .about__card {
    margin-top: 0;
    border-radius: 0;
  }
}
@media (max-width: 767px) {
  .about__card {
    padding: 56px 0;
  }
}
.about__title {
  font-size: 80px;
  line-height: 1.1;
  text-align: center;
  font-weight: 300;
  color: var(--text-color);
}
@media (max-width: 1366px) {
  .about__title {
    font-size: 64px;
  }
}
@media (max-width: 1025px) {
  .about__title {
    font-size: 32px;
  }
}
.about__title b {
  font-weight: 900;
  color: #000;
  text-transform: uppercase;
}
.about__desc {
  padding-left: 30px;
  padding-bottom: 60px;
  color: #000;
  font-weight: 900;
  font-size: 34px;
  line-height: 1.5;
}
@media (max-width: 1025px) {
  .about__desc {
    padding-bottom: 32px;
    padding-left: 0;
  }
}
.about__list {
  padding: 0 32px;
}
@media (max-width: 1025px) {
  .about__list {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
}
.about__item {
  display: inline-block;
  vertical-align: text-top;
  position: relative;
  padding: 0 10px;
  color: #000;
  font-size: 20px;
  line-height: 1.5;
  text-transform: lowercase;
  max-width: 25%;
  font-weight: 400;
  counter-increment: li;
}
@media (max-width: 1025px) {
  .about__item {
    margin-left: auto;
    margin-right: auto;
  }
  .about__item:nth-of-type(1) {
    max-width: 196px;
    margin-bottom: 84px;
  }
  .about__item:nth-of-type(2) {
    max-width: 260px;
    margin-bottom: 53px;
  }
  .about__item:nth-of-type(3) {
    max-width: 280px;
    margin-bottom: 49px;
  }
  .about__item:nth-of-type(4) {
    max-width: 300px;
  }
}
.about__item:before {
  content: "" counter(li);
  position: absolute;
  top: -30px;
  left: 70px;
  color: var(--text-color);
  font-size: 100px;
  line-height: 1;
  opacity: 0.6;
  font-weight: 900;
}
@media (max-width: 1025px) {
  .about__item:before {
    left: 50%;
    transform: translateX(-50%);
  }
}
.about__item:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background: #000;
}

.programs {
  padding: 80px 0;
  background: #1B1934;
}
@media (max-width: 1025px) {
  .programs {
    padding: 56px 0;
  }
}
.programs__title, .programs__box {
  padding-left: 35px;
}
@media (max-width: 1025px) {
  .programs__title, .programs__box {
    padding-left: 0;
  }
}
.programs__title {
  margin-bottom: -15px;
  font-weight: 900;
  font-size: 80px;
  line-height: 1.5;
  color: #FFFFFF;
}
@media (max-width: 1025px) {
  .programs__title {
    margin-bottom: 0;
    padding-bottom: 15px;
    font-size: 40px;
  }
}
.programs .container {
  padding-bottom: 15px;
}
.programs__list {
  padding-top: 32px;
  margin-top: -32px;
}
@media (max-width: 767px) {
  .programs__list {
    margin: auto;
    padding: 0;
  }
}
.programs__item {
  transition: transform var(--fast);
  cursor: grab;
}
.programs__item:active {
  cursor: grabbing;
}
.programs__item:hover {
  transform: translateY(-32px);
}
@media (max-width: 767px) {
  .programs__item:hover {
    transform: none;
  }
}
.programs__item:hover .programs__img:before,
.programs__item:hover .programs__btn {
  opacity: 1;
}
.programs__img, .programs__content {
  max-width: 290px;
  min-width: 290px;
  width: 100%;
  margin: auto;
}
@media (max-width: 767px) {
  .programs__img, .programs__content {
    max-width: 100%;
    min-width: 100%;
  }
}
.programs__img {
  position: relative;
  overflow: hidden;
  max-width: 290px;
  min-width: 290px;
  width: 100%;
  margin: auto;
}
@media (max-width: 767px) {
  .programs__img {
    max-width: 100%;
    min-width: 100%;
    margin: auto;
  }
}
.programs__img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 25, 52, 0.4);
  opacity: 0;
  transition: opacity var(--fast, 1.3s linear);
}
.programs__img img {
  width: 300px;
  height: 285px;
  pointer-events: none;
  user-select: none;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 767px) {
  .programs__img img {
    width: 100%;
    margin: auto;
    aspect-ratio: 1/1;
  }
}
@media (max-width: 767px) {
  .programs__content {
    max-width: 285px;
    margin: auto;
  }
}
.programs__btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 230px;
  background: #F9CB67;
  border: 1px solid #FFCC66;
  border-radius: 30px;
  opacity: 0;
  transition: opacity var(--fast);
  cursor: pointer;
  user-select: none;
}
@media (max-width: 767px) {
  .programs__btn {
    width: 200px;
  }
}
.programs__btn span {
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.0622em;
  text-transform: uppercase;
  color: #1B1934;
}
.programs__btn svg {
  display: block;
  width: 33px;
  height: 14px;
  margin-right: 2px;
  fill: #1D1D1B;
}
.programs__content {
  margin-top: 16px;
  user-select: none;
  width: 100%;
}
.programs__content h3 {
  padding-bottom: 8px;
  font-weight: 900;
  font-size: 20px;
  line-height: 1.5;
  color: #fff;
}
.programs__content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #C4C4C4;
}
.programs__content p:not(:last-of-type) {
  padding-bottom: 26px;
}
.programs__box {
  display: flex;
  max-width: var(--container-width);
  margin: auto;
  overflow: visible;
  position: relative;
}
@media (max-width: 767px) {
  .programs__box {
    padding: 0 1em;
    max-width: 100%;
    width: 100%;
    display: block;
  }
}
.programs__controls {
  display: flex !important;
  align-items: flex-start;
  margin-left: 15px;
  margin-right: 35px;
  margin-top: 50px;
}
@media (max-width: 1025px) {
  .programs__controls {
    margin-left: 3rem;
  }
}
@media (max-width: 767px) {
  .programs__controls {
    margin: 0;
  }
}
.programs__controls button {
  fill: #F9CB67;
  background: none;
  border: 0;
  outline: none !important;
  cursor: pointer;
  transition: var(--fast);
  padding: 0;
}
@media (max-width: 767px) {
  .programs__controls button {
    position: absolute;
    top: 125px;
    z-index: 2;
  }
}
.programs__controls button:first-of-type {
  margin-right: 20px;
}
@media (max-width: 1025px) {
  .programs__controls button:first-of-type {
    left: 75px;
  }
}
@media (max-width: 700px) {
  .programs__controls button:first-of-type {
    margin-right: 0;
    left: 35px;
  }
}
@media (max-width: 1025px) {
  .programs__controls button:last-of-type {
    right: 75px;
  }
}
@media (max-width: 700px) {
  .programs__controls button:last-of-type {
    right: 35px;
  }
}
.programs__controls button[disabled], .programs__controls button:hover, .programs__controls button:active {
  opacity: 0.75;
}
.programs__controls button [disabled] {
  cursor: default;
}
.programs__controls button svg {
  width: 60px;
  height: 60px;
}
@media (max-width: 767px) {
  .programs__controls button svg {
    width: 40px;
    height: 40px;
  }
}
.programs__controls[style="display: none;"] button {
  opacity: 0.75;
  pointer-events: none;
}
.programs .tns-ovh {
  padding-top: 32px;
}
@media (max-width: 767px) {
  .programs .tns-ovh {
    padding-top: 0;
    padding-bottom: 32px;
  }
}
@media (min-width: 700px) and (max-width: 1025px) {
  .programs .tns-outer {
    max-width: calc(100% - 6em);
    margin: auto;
  }
}

.podcasts {
  background: #fff;
}
.podcasts__controls button {
  fill: #1B1934;
}
.podcasts__title {
  color: var(--text-color);
}
.podcasts__content h3, .podcasts__content p {
  color: #1D1D1B;
}
@media (max-width: 767px) {
  .podcasts .tns-nav button {
    background: #1B1934;
  }
}
@media (max-width: 767px) {
  .podcasts .tns-nav .tns-nav-active {
    background: rgba(27, 25, 52, 0.7);
  }
}

@media (max-width: 767px) {
  .tns-nav {
    display: flex;
    justify-content: center;
    gap: 12px;
  }
}
@media (max-width: 767px) {
  .tns-nav button {
    display: block;
    width: 16px;
    height: 16px;
    font-size: 0;
    line-height: 0;
    background: #F9CB67;
    border-radius: 50%;
    border: 0;
    transform: scale(0.5);
  }
}
@media (max-width: 767px) {
  .tns-nav .tns-nav-active {
    background: rgba(249, 203, 103, 0.61);
  }
}

.tns-outer {
  width: 100%;
}

.platform {
  margin-top: 109px;
  padding-bottom: 80px;
  background: #F9CB67;
}
@media (max-width: 1025px) {
  .platform {
    padding: 56px 0;
    margin-top: 20px;
  }
}
.platform__decor {
  position: absolute;
  top: -108px;
  right: -170px;
  height: auto;
}
@media (max-width: 1366px) {
  .platform__decor {
    right: -40px;
  }
}
@media (max-width: 1025px) {
  .platform__decor {
    max-height: 226px;
    right: -30px;
    top: -90px;
  }
}
@media (max-width: 767px) {
  .platform__decor {
    display: none;
  }
}
.platform__decor-mob {
  display: none;
  position: absolute;
  pointer-events: none;
}
@media (max-width: 767px) {
  .platform__decor-mob {
    display: block;
    top: -110px;
    right: 0px;
  }
}
.platform__decor-anim {
  pointer-events: none;
}
.platform__decor-anim g {
  opacity: 0;
  transform: scale(0.7);
  transform-origin: center;
}
._animation .platform__decor-anim g {
  animation: text-go var(--fast) forwards;
}
.platform__decor-anim g:nth-child(1) {
  animation-delay: 0.1s;
}
.platform__decor-anim g:nth-child(2) {
  animation-delay: 0.2s;
}
.platform__decor-anim g:nth-child(3) {
  animation-delay: 0.3s;
}
.platform__decor-anim g:nth-child(4) {
  animation-delay: 0.4s;
}
.platform__decor-anim g:nth-child(5) {
  animation-delay: 0.5s;
}
.platform__decor-anim g:nth-child(6) {
  animation-delay: 0.6s;
}
.platform__decor-anim g:nth-child(7) {
  animation-delay: 0.7s;
}
.platform__decor-anim g:nth-child(8) {
  animation-delay: 0.8s;
}
.platform__decor-anim g:nth-child(9) {
  animation-delay: 0.9s;
}
.platform__decor-anim g:nth-child(10) {
  animation-delay: 1s;
}
.platform__img {
  position: relative;
}
.platform__img:before {
  content: "";
  position: absolute;
  bottom: 12px;
  right: 12px;
  width: 40px;
  height: 40px;
  background: url("../img/yt.png") no-repeat center/cover;
  z-index: 1;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .platform__img:before {
    display: none;
  }
}
.platform__title, .platform__text {
  padding-left: 210px;
}
@media (max-width: 1366px) {
  .platform__title, .platform__text {
    padding-left: 110px;
  }
}
@media (max-width: 1025px) {
  .platform__title, .platform__text {
    padding-left: 0;
  }
}
.platform__title {
  padding-top: 200px;
  padding-bottom: 55px;
  font-weight: 900;
  font-size: 102px;
  line-height: 1.5;
  color: #1B1934;
}
@media (max-width: 1025px) {
  .platform__title {
    font-size: 32px;
    padding-top: 100px;
    padding-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .platform__title {
    padding-top: 0;
  }
}
.platform__text {
  max-width: 740px;
  font-size: 20px;
  line-height: 1.5;
}
.platform__text b {
  font-weight: 700;
}
.platform__slider {
  display: flex;
  position: relative;
  padding-top: 52px;
  overflow: hidden;
  z-index: 2;
}
@media (min-width: 1250px) {
  .platform__slider {
    margin-right: 50px;
  }
}
@media (max-width: 1025px) {
  .platform__slider {
    padding-top: 32px;
  }
}
.platform__img img {
  width: 100%;
  height: 170px;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 767px) {
  .platform__img img {
    object-fit: cover;
    object-position: center;
    height: 200px;
    width: 100%;
    margin: auto;
    aspect-ratio: 1/1;
  }
}
.platform__content {
  position: relative;
  padding-top: 16px;
  line-height: 1.5;
  color: #1D1D1B;
  width: 100%;
}
@media (max-width: 767px) {
  .platform__content {
    max-width: 100%;
    margin: auto;
  }
}
@media (max-width: 767px) {
  .platform__content:before {
    content: "";
    position: absolute;
    top: -58px;
    right: 12px;
    width: 40px;
    height: 40px;
    background: url("../img/yt.png") no-repeat center/cover;
    z-index: 1;
    border-radius: 50%;
  }
}
.platform__content h3 {
  padding-bottom: 8px;
  font-weight: 900;
  font-size: 20px;
}
.platform__content p {
  font-weight: 400;
  font-size: 16px;
}
.platform__content p:not(:last-of-type) {
  padding-bottom: 8px;
}
.platform__controls {
  margin-top: 15px;
  margin-left: 30px;
}
@media (max-width: 1025px) {
  .platform__controls {
    margin-left: 3rem;
  }
}
@media (max-width: 767px) {
  .platform__controls {
    margin: 0;
  }
}
.platform__controls button {
  fill: #1B1934;
}
@media (max-width: 767px) {
  .platform__controls button {
    position: absolute;
    top: 110px;
    z-index: 3;
  }
}
@media (max-width: 767px) {
  .platform__controls button:first-of-type {
    left: 18px;
  }
}
@media (max-width: 767px) {
  .platform__controls button:last-of-type {
    right: 18px;
  }
}
.platform .container {
  position: relative;
}

@keyframes text-go {
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.support-page {
  position: relative;
  min-height: 100vh;
  background: #1B1934;
}
.support__title {
  padding-top: 173px;
  padding-bottom: 40px;
  font-weight: 900;
  font-size: 80px;
  line-height: 1.1;
  color: #FFF;
}
@media (max-width: 1025px) {
  .support__title {
    font-size: 40px;
    padding-top: 135px;
    padding-bottom: 32px;
  }
}
.support__subtitle {
  padding-bottom: 80px;
  font-size: 24px;
  color: #FFF;
}
@media (max-width: 1025px) {
  .support__subtitle {
    padding-bottom: 56px;
  }
}
.support__box {
  position: relative;
}
.support__tab {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.support__tab._mob {
  display: none;
}
@media (max-width: 767px) {
  .support__tab ._desk {
    display: none;
  }
  .support__tab._mob {
    display: block;
    margin-top: 24px;
  }
  .support__tab._mob button {
    margin: auto;
  }
}
.support__tab button {
  display: grid;
  place-items: center;
  max-width: 381px;
  width: 100%;
  height: 66px;
  border: 1px solid #FFCC66;
  border-radius: 8px;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.0622em;
  text-transform: uppercase;
  color: #1B1934;
}
.support__tab button:first-of-type {
  margin-right: 27px;
}
@media (max-width: 767px) {
  .support__tab button:first-of-type {
    margin: auto;
  }
}
@media (max-width: 1025px) {
  .support__tab button {
    max-width: 100%;
    font-size: 16px;
    line-height: 20p;
  }
}
.support__tab button.active {
  background: #F9CB67;
}
.support__tab button:not(.active) {
  background: #FFE4A9;
  cursor: pointer;
}
@media (max-width: 767px) {
  .support__tab button.active {
    background: #FFE4A9;
  }
  .support__tab button:not(.active) {
    background: #F9CB67;
  }
}
.support__form {
  position: relative;
  padding: 60px 104px 80px 104px;
}
@media (max-width: 1025px) {
  .support__form {
    padding: 32px;
  }
}
@media (max-width: 767px) {
  .support__form {
    padding: 32px 0;
  }
}
.support__form.hidden {
  display: none;
}
.support__form:before {
  content: "";
  position: absolute;
  top: -15px;
  left: 0;
  width: 100%;
  height: calc(100% + 15px);
  border: 1px solid #fff;
}
@media (max-width: 767px) {
  .support__form:before {
    border: 0;
    left: -1em;
    width: calc(2em + 100%);
    border-top: 1px solid #fff;
  }
}
.support__form form {
  position: relative;
  z-index: 1;
  animation: fadein 2s;
}
.support__form .form__sum {
  padding-bottom: 60px;
  font-size: 24px;
  line-height: 1.5;
  color: #fff;
}
@media (max-width: 1025px) {
  .support__form .form__sum {
    text-align: center;
    padding-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .support__form .form__sum {
    text-align: left;
  }
}
.support__form .form__radios {
  display: grid;
  padding-bottom: 56px;
  gap: 40px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
@media (max-width: 1025px) {
  .support__form .form__radios {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 24px;
  }
}
@media (max-width: 767px) {
  .support__form .form__radios {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
}
.support__form .form__email {
  padding-bottom: 80px;
  font-size: 24px;
  line-height: 1.5;
  color: #fff;
}
@media (max-width: 1025px) {
  .support__form .form__email {
    text-align: center;
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .support__form .form__email {
    text-align: left;
  }
}
.support__form .form__row-email {
  margin-bottom: 50px;
  border-bottom: 1px solid #fff;
  max-width: 377px;
}
.support__form .form__row-email input {
  color: #fff;
  outline: none;
}
.support__form .form__row-email::placeholder {
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.6);
}
.support__form .form__row-email input[type=email] {
  padding: 4px 8px;
  height: 40px;
  width: 100%;
  background: transparent;
  border: none;
}
.support__form .form__btn {
  display: block;
  max-width: 377px;
  width: 100%;
  height: 60px;
  background: #F9CB67;
  border: 1px solid #FFCC66;
  border-radius: 30px;
  letter-spacing: 0.0622em;
  text-transform: uppercase;
  color: #1B1934;
  font-weight: 700;
  font-family: inherit;
  transition: opacity var(--fast);
  cursor: pointer;
}
@media (max-width: 1025px) {
  .support__form .form__btn {
    margin: auto;
  }
}
.support__form .form__btn:hover {
  opacity: 0.75;
}
.support__form .form-control {
  display: grid;
  place-items: center;
  position: relative;
  padding: 0 5px;
  width: 235px;
  height: 60px;
  font-weight: 900;
  font-size: 24px;
  line-height: 110%;
  color: #fff;
}
@media (max-width: 1025px) {
  .support__form .form-control {
    margin: auto;
  }
}
.support__form .form-control span {
  position: relative;
  z-index: 1;
  pointer-events: none;
}
.support__form .form-control input[type=radio] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 8px;
  cursor: pointer;
}
.support__form .form-control [type=radio]:checked {
  background: #fff;
  cursor: default;
}
.support__form .form-control [type=radio]:checked ~ span {
  color: #000;
}
.support__decor {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  height: 100%;
}
@media (max-width: 1025px) {
  .support__decor {
    display: none;
  }
}
.support-contact {
  padding-top: 80px;
  padding-bottom: 112px;
  color: #FFF;
}
@media (max-width: 1025px) {
  .support-contact {
    padding-top: 56px;
    padding-bottom: 113px;
  }
}
.support-contact__title {
  padding-bottom: 40px;
  font-weight: 900;
  font-size: 80px;
  line-height: 110%;
  text-transform: uppercase;
}
@media (max-width: 1025px) {
  .support-contact__title {
    font-size: 34px;
    padding-bottom: 32px;
  }
}
.support-contact__box {
  font-size: 24px;
  line-height: 29px;
}
.support-contact__box > div {
  display: inline-block;
}
@media (max-width: 1025px) {
  .support-contact__box > div {
    display: block;
  }
}
.support-contact__box > div:first-of-type {
  padding-right: 126px;
}
@media (max-width: 1025px) {
  .support-contact__box > div:first-of-type {
    padding-right: 0;
    padding-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .support-contact__box > div:last-of-type {
    font-size: 13px;
  }
}
.support-contact__box b {
  display: block;
  padding-bottom: 16px;
  font-weight: 700;
}
.support-contact__box p {
  font-weight: 400;
  line-height: 1.5;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.footer {
  padding-top: 175px;
  padding-bottom: 143px;
  position: relative;
}
@media (max-width: 1025px) {
  .footer {
    padding-top: 56px;
    padding-bottom: 113px;
  }
}
.footer .container {
  padding-left: 50px;
}
@media (max-width: 1025px) {
  .footer .container {
    padding-left: 3rem;
  }
}
@media (max-width: 767px) {
  .footer .container {
    padding-left: 1em;
  }
}
.footer__title {
  padding-bottom: 32px;
  font-weight: 900;
  font-size: 80px;
  line-height: 1.1;
  text-transform: uppercase;
  color: #1B1934;
}
@media (max-width: 1025px) {
  .footer__title {
    font-size: 40px;
  }
}
.footer__decor {
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
@media (max-width: 1025px) {
  .footer__decor {
    display: none;
  }
}
.footer__box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 1;
}
@media (max-width: 1025px) {
  .footer__box {
    grid-template-columns: 1fr;
  }
}
.footer__contact > div {
  max-width: 510px;
}
.footer__contact b {
  display: block;
  padding-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}
.footer__contact p {
  font-size: 24px;
  line-height: 1.5;
}
.footer__contact p:not(:last-of-type) {
  padding-bottom: 32px;
}
@media (max-width: 767px) {
  .footer__contact p:last-of-type {
    font-size: 13px;
  }
}
.footer__social {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin-bottom: 16px;
  height: 100%;
}
@media (max-width: 1025px) {
  .footer__social {
    padding-left: 0;
    margin-top: 32px;
    margin-bottom: 0;
  }
}
.footer__social b {
  display: block;
  padding-bottom: 21px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}
.footer__social li {
  display: inline-block;
  width: 60px;
  height: 60px;
  transition: opacity var(--fast);
}
.footer__social li:hover {
  opacity: 0.75;
}
.footer__social li:not(:last-of-type) {
  margin-right: 16px;
}
.footer__social div {
  margin-top: auto;
}
@media (max-width: 1025px) {
  .footer__social div {
    margin-top: 56px;
  }
}