@import "vars2";

.platform {
  margin-top: 109px;
  padding-bottom: 80px;
  background: #F9CB67;

  @media (max-width: $tablet) {
    padding: 56px 0;
    margin-top: 20px;
  }

  &__decor {
    position: absolute;
    top: -108px;
    right: -170px;
    height: auto;

    @media (max-width: $laptop) {
      right: -40px;
    }

    @media (max-width: $tablet) {
      max-height: 226px;
      right: -30px;
      top: -90px;
    }

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__decor-mob {
    display: none;
    position: absolute;
    pointer-events: none;
    
    @media (max-width: $mobile) {
      display: block;
      top: -110px;
      right: 0px;
    }
  }

  &__decor-anim {
    pointer-events: none;

    g {
      opacity: 0;
      transform: scale(.7);
      transform-origin: center;

      ._animation &  {
        animation: text-go var(--fast) forwards;
      }
    }

    @for $i from 1 to 11 {
      g:nth-child(#{$i}) {
        animation-delay: $i * 0.1s;
      }
    }
  }

  &__img {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: 12px;
      right: 12px;
      width: 40px;
      height: 40px;
      background: url('../img/yt.png') no-repeat center/cover;
      z-index: 1;
      border-radius: 50%;

      @media (max-width: $mobile) {
        display: none;
      }
    }
  }

  &__title,
  &__text {
    padding-left: 210px;

    @media (max-width: $laptop) {
      padding-left: 110px;
    }

    @media (max-width: $tablet) {
      padding-left: 0;
    }

    @media (max-width: $mobile) {
      
    }
  }

  &__title {
    padding-top: 200px;
    padding-bottom: 55px;
    font-weight: 900;
    font-size: 102px;
    line-height: 1.5;
    color: #1B1934;

    @media (max-width: $tablet) {
      font-size: 32px;
      padding-top: 100px;
      padding-bottom: 16px;
    }

    @media (max-width: $mobile) {
      padding-top: 0;
    }
  }

  &__text {
    max-width: 740px;
    font-size: 20px;
    line-height: 1.5;
    
    b {
      font-weight: 700;
    }
  }

  &__slider {
    display: flex;
    position: relative;
    padding-top: 52px;
    overflow: hidden;
    z-index: 2;

    @media (min-width: 1250px) {
      margin-right: 50px;
    }

    @media (max-width: $tablet) {
      padding-top: 32px;
    }
    @media (max-width: $mobile) {
      
    }
  }

  &__img {

    img {
      width: 100%;
      height: 170px;
      object-fit: cover;
      object-position: center;

      @media (max-width: $mobile) {
        object-fit: cover;
        object-position: center;
        height: 200px;
        width: 100%;
        margin: auto;
        aspect-ratio: 1/1;
      }
    }
  }

  &__content {
    position: relative;
    padding-top: 16px;
    line-height: 1.5;
    color: #1D1D1B;
    width: 100%;

    @media (max-width: $mobile) {
      max-width: 100%;
      margin: auto;
    }

    &:before {
      
      @media (max-width: $mobile) {
        content: '';
        position: absolute;
        top: -58px;
        right: 12px;
        width: 40px;
        height: 40px;
        background: url('../img/yt.png') no-repeat center/cover;
        z-index: 1;
        border-radius: 50%;
      }
    }

    h3 {
      padding-bottom: 8px;
      font-weight: 900;
      font-size: 20px;
    }

    p {
      font-weight: 400;
      font-size: 16px;

      &:not(:last-of-type) {
        padding-bottom: 8px;
      }
    }
  }

  &__controls {
    margin-top: 15px;
    margin-left: 30px;

    @media (max-width: $tablet) {
      margin-left: $tablet-padding;
    }

    @media (max-width: $mobile) {
      margin: 0; 
    }

    button {
      fill: #1B1934;

      @media (max-width: $mobile) {
        position: absolute;
        top: 110px;
        z-index: 3;
      }

      &:first-of-type {
        @media (max-width: $mobile) {
          left: 18px;
        }
      }

      &:last-of-type {
        @media (max-width: $mobile) {
          right: 18px;
        }
      }
    }
  }

  .container {
    position: relative;
  }
}

@keyframes text-go {
  50% {

  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
