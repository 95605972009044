@import "vars2";

.programs {
  padding: 80px 0;
  background: #1B1934;

  @media (max-width: $tablet) {
    padding: 56px 0;
  }

  &__title,
  &__box {
    padding-left: 35px;

    @media (max-width: $tablet) {
      padding-left: 0;
    }
  }

  &__title {
    margin-bottom: -15px;
    font-weight: 900;
    font-size: 80px;
    line-height: 1.5;
    color: #FFFFFF;

    @media (max-width: $tablet) {
      margin-bottom: 0;
      padding-bottom: 15px;
      font-size: 40px;
    }
  }

  .container {
    padding-bottom: 15px;
  }

  &__list {
    padding-top: 32px;
    margin-top: -32px;

    @media (max-width: $mobile) {
      margin: auto;
      padding: 0;
    }
  }

  &__item {
    transition: transform var(--fast);
    cursor: grab;

    &:active {
      cursor: grabbing
    }

    &:hover {
      transform: translateY(-32px);

      @media (max-width: $mobile) {
        transform: none;   
      }

      .programs__img:before,
      .programs__btn {
        opacity: 1;
      }
    }
  }

  &__img,
  &__content {
    max-width: 290px;
    min-width: 290px;
    width: 100%;
    margin: auto;
    
    @media (max-width: $mobile) {
      max-width: 100%;
      min-width: 100%;
    }
  }

  &__img {
    position: relative;
    overflow: hidden;
    max-width: 290px;
    min-width: 290px;
    width: 100%;
    margin: auto;
    
    @media (max-width: $mobile) {
      max-width: 100%;
      min-width: 100%;
      margin: auto;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(27, 25, 52, 0.4);
      opacity: 0;
      transition: opacity var(--fast, 1.3s linear);
    }

    img {
      width: 300px;
      height: 285px;
      pointer-events: none;
      user-select: none;
      object-fit: cover;
      object-position: center;

      @media (max-width: $mobile) {
        width: 100%;
        // height: auto;
        margin: auto;
        aspect-ratio: 1/1;
      }
    }
  }

  &__content {
    @media (max-width: $mobile) {
      max-width: 285px;
      margin: auto;
    }
  }

  &__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 230px;
    background: #F9CB67;
    border: 1px solid #FFCC66;
    border-radius: 30px;
    opacity: 0;
    transition: opacity var(--fast);
    cursor: pointer;
    user-select: none;

    @media (max-width: $mobile) {
      width: 200px;
    }

    span {
      font-weight: 700;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.0622em;text-transform: uppercase;
      color: #1B1934;
    }

    svg {
      display: block;
      width: 33px;
      height: 14px;
      margin-right: 2px;
      fill:#1D1D1B;
    }
  }

  &__content {
    margin-top: 16px;
    user-select: none;
    width: 100%;

    h3 {
      padding-bottom: 8px;
      font-weight: 900;
      font-size: 20px;
      line-height: 1.5;
      color: #fff;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      color: #C4C4C4;
      
      &:not(:last-of-type) {
        padding-bottom: 26px;
      }
    }
  }

  &__box {
    display: flex;
    max-width: var(--container-width);
    margin: auto;
    overflow: visible;
    position: relative;

    @media (max-width: $mobile) {
      padding: 0 $mob-padding;
      max-width: 100%;
      width: 100%;
      display: block;
    }
  }

  &__controls {
    display: flex !important;
    align-items: flex-start;
    margin-left: 15px;
    margin-right: 35px;
    margin-top: 50px;

    @media (max-width: $tablet) {
      margin-left: $tablet-padding;
    }

    @media (max-width: $mobile) {
      margin: 0; 
    }
    
    button {
      fill: #F9CB67;
      background: none;
      border: 0;
      outline: none !important;
      cursor: pointer;
      transition: var(--fast);
      padding: 0;
      
      @media (max-width: $mobile) {
        position: absolute;
        top: 125px;
        z-index: 2;
      }

      &:first-of-type {
        margin-right: 20px;

        @media (max-width: $tablet) {
          left: 75px;
        }

        @media (max-width: 700px) {
          margin-right: 0;
          left: 35px;
        }
      }

      &:last-of-type {

        @media (max-width: $tablet) {
          right: 75px;
        }

        @media (max-width: 700px) {
          right: 35px;
        }
      }

      &[disabled],
      &:hover,
      &:active {
        opacity: 0.75;
      }

      [disabled] {
        cursor: default;
      }
      
      svg {
        width: 60px;
        height: 60px;

        @media (max-width: $mobile) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  &__controls[style="display: none;"] {
    button {
      opacity: 0.75;
      pointer-events: none;
    }
  }

  .tns-ovh {
    padding-top: 32px;

    @media (max-width: $mobile) {
      padding-top: 0;
      padding-bottom: 32px;
    }
  }

  .tns-outer {
  
    @media (min-width: 700px) and (max-width: $tablet) {
      max-width: calc(100% - 6em);
      margin: auto;
    }
  }
}


.podcasts {
  background: #fff;

  &__controls {

    button {
      fill: #1B1934;
    }
  }

  &__title {
    color: var(--text-color);
  }

  &__content {
    h3, p {
      color: #1D1D1B;
    }
  }

  .tns-nav {
  
    button {
      @media (max-width: $mobile) {
        background: #1B1934;
      }
    }
    
    .tns-nav-active {
      @media (max-width: $mobile) {
        background: rgba(27, 25, 52, 0.7);
      }
    }
  }
}


.tns-nav {

  @media (max-width: $mobile) {
    display: flex;
    justify-content: center;
    gap: 12px;
  }

  button {
    @media (max-width: $mobile) {
      display: block;
      width: 16px;
      height: 16px;
      font-size: 0;
      line-height: 0;
      background: #F9CB67;
      border-radius: 50%;
      border: 0;
      transform: scale(0.5);
    }
  }
  
  .tns-nav-active {
    @media (max-width: $mobile) {
      background: rgba(249, 203, 103, 0.61);
    }
  }
}

.tns-outer {
  width: 100%;
}