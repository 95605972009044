@import "vars2";

.support {
  &-page {
    position: relative;
    min-height: 100vh;
    background: #1B1934;
  }

  &__title {
    padding-top: 173px;
    padding-bottom: 40px;
    font-weight: 900;
    font-size: 80px;
    line-height: 1.1;
    color: #FFF;

    @media (max-width: $tablet) {
      font-size: 40px;
      padding-top: 135px;
      padding-bottom: 32px;
    }

    @media (max-width: $mobile) {
      
    }
  }

  &__subtitle {
    padding-bottom: 80px;
    font-size: 24px;
    color: #FFF;

    @media (max-width: $tablet) {
      padding-bottom: 56px;
    }

    @media (max-width: $mobile) {
      
    }
  }

  &__box {
    position: relative;
  }

  &__tab {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;

    &._mob {
      display: none;
    }

    @media (max-width: $tablet) {
  
    }
    @media (max-width: $mobile) {
      ._desk {
        display: none;
      }

      &._mob {
        display: block;
        margin-top: 24px;

        button {
          margin: auto;
        }
      }
    }

    button {
      display: grid;
      place-items: center;
      max-width: 381px;
      width: 100%;
      height: 66px;
      border: 1px solid #FFCC66;
      border-radius: 8px;

      font-weight: 900;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.0622em;
      text-transform: uppercase;
      color: #1B1934;

      &:first-of-type {
        margin-right: 27px;

        @media (max-width: $mobile) {
          margin: auto;
        }
      }

      @media (max-width: $tablet) {
        max-width: 100%;
        font-size: 16px;
        line-height: 20p;
      }

      @media (max-width: $mobile) {
        
      }
    }
    
    button.active {
      background: #F9CB67;
    }
    
    button:not(.active) {
      background: #FFE4A9;
      cursor: pointer;
    }

    @media (max-width: $mobile) {
      button.active {
        background: #FFE4A9;
      }
      
      button:not(.active) {
        background: #F9CB67;
      }
    }
  }

  &__form {
    position: relative;
    padding: 60px 104px 80px 104px;
    
    @media (max-width: $tablet) {
      padding: 32px;
    }
    
    @media (max-width: $mobile) {
      padding: 32px 0;
    }

    &.hidden {
      display: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: -15px;
      left: 0;
      width: 100%;
      height: calc(100% + 15px);
      border: 1px solid #fff;

      @media (max-width: $mobile) {
        border:0;
        left: #{$mob-padding * -1};
        width: #{calc(($mob-padding * 2) + 100%)};
        border-top: 1px solid #fff;
      }
    }
    
    form {
      position: relative;
      z-index: 1;
      animation: fadein 2s;
    }
    
    .form {
      &__sum {
        padding-bottom: 60px;
        font-size: 24px;
        line-height: 1.5;
        color: #fff;

        @media (max-width: $tablet) {
          text-align: center;
          padding-bottom: 24px;
        }

        @media (max-width: $mobile) {
          text-align: left;
        }
      }
      
      &__radios {
        display: grid;
        padding-bottom: 56px;
        gap: 40px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);

        @media (max-width: $tablet) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, 1fr);
          gap: 24px;
        }

        @media (max-width: $mobile) {
          grid-template-columns: auto;
          grid-template-rows: auto;
        }
      }

      &__email {
        padding-bottom: 80px;
        font-size: 24px;
        line-height: 1.5;
        color: #fff;

        @media (max-width: $tablet) {
          text-align: center;
          padding-bottom: 40px;
        }

        @media (max-width: $mobile) {
          text-align: left;
        }
      }

      &__row-email {
        margin-bottom: 50px;
        border-bottom: 1px solid #fff;
        max-width: 377px;

        input {
          color: #fff;
          outline: none;
        }
        

        @media (max-width: $tablet) {
  
        }
        @media (max-width: $mobile) {
          
        }

        &::placeholder {
          font-family: inherit;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 150%;
          color: rgba(255, 255, 255, 0.6);
        }

        input[type="email"] {
          padding: 4px 8px;
          height: 40px;
          width: 100%;
          background: transparent;
          border: none;
        }
      }

      &__btn {
        display: block;
        max-width: 377px;
        width: 100%;
        height: 60px;
        background: #F9CB67;
        border: 1px solid #FFCC66;
        border-radius: 30px;
        letter-spacing: 0.0622em;
        text-transform: uppercase;
        color: #1B1934;
        font-weight: 700;
        font-family: inherit;
        transition: opacity var(--fast);
        cursor: pointer;

        @media (max-width: $tablet) {

          margin: auto;
        }

        @media (max-width: $mobile) {
          
        }

        &:hover {
          opacity: 0.75;
        }
      }
    }

    .form-control {
      display: grid;
      place-items: center;
      position: relative;
      padding: 0 5px;
      width: 235px;
      height: 60px;
      font-weight: 900;
      font-size: 24px;
      line-height: 110%;
      color: #fff;

      @media (max-width: $tablet) {
        margin: auto;
      }


      span {
        position: relative;
        z-index: 1;
        pointer-events: none;
      }

      input[type="radio"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;

        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        border: 1px solid #fff;
        border-radius: 8px;
        cursor: pointer;
      }

      [type="radio"]:checked {
        background: #fff;
        cursor: default;

        & ~ span {
          color: #000;
        }
      }
    }
  }

  &__decor {
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    height: 100%;

    @media (max-width: $tablet) {
      display: none;
    }

    @media (max-width: $mobile) {
      
    }
  }
}

.support-contact {
  padding-top: 80px;
  padding-bottom: 112px;
  color: #FFF;

  @media (max-width: $tablet) {
    padding-top: 56px;
    padding-bottom: 113px;
  }

  &__title {
    padding-bottom: 40px;
    font-weight: 900;
    font-size: 80px;
    line-height: 110%;
    text-transform: uppercase;

    @media (max-width: $tablet) {
      font-size: 34px;
      padding-bottom: 32px;
    }
  }

  &__box {
    font-size: 24px;
    line-height: 29px;

    @media (max-width: $tablet) {
  
    }
    @media (max-width: $mobile) {
      
    }

    & > div {
      display: inline-block;

      @media (max-width: $tablet) {
        display: block;
      }
      
      &:first-of-type {
        padding-right: 126px;

        @media (max-width: $tablet) {
          padding-right: 0;
          padding-bottom: 16px;
        }
        
        @media (max-width: $mobile) {
          
        }
      }

      &:last-of-type {
        @media (max-width: $mobile) {
          font-size: 13px;
        }
      }
    }

    b {
      display: block;
      padding-bottom: 16px;
      font-weight: 700;
    }

    p {
      font-weight: 400;
      line-height: 1.5;
    }
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}