@import "vars2";

.footer {
  padding-top: 175px;
  padding-bottom: 143px;
  position: relative;

  @media (max-width: $tablet) {
    padding-top: 56px;
    padding-bottom: 113px;
  }

  @media (max-width: $mobile) {
    
  }

  .container {
    padding-left: 50px;

    @media (max-width: $tablet) {
      padding-left: $tablet-padding;
    }

    @media (max-width: $mobile) {
      padding-left: $mob-padding;
    }
  }
  
  &__title {
    padding-bottom: 32px;
    font-weight: 900;
    font-size: 80px;
    line-height: 1.1;
    text-transform: uppercase;
    color: #1B1934;
    
    @media (max-width: $tablet) {
      font-size: 40px;
    }
  }

  &__decor {
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 1;

    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
    }
  }

  &__contact {

    & > div {
      max-width: 510px;
    }
    
    b {
      display: block;
      padding-bottom: 16px;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
    }

    p {
      font-size: 24px;
      line-height: 1.5;

      &:not(:last-of-type) {
        padding-bottom: 32px;
      }

      &:last-of-type {
        @media (max-width: $mobile) {
          font-size: 13px;
        }
      }
    }
  }

  &__social {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    margin-bottom: 16px;
    height: 100%;
    
    @media (max-width: $tablet) {
      padding-left: 0;
      margin-top: 32px;
      margin-bottom: 0;
    }

    b {
      display: block;
      padding-bottom: 21px;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
    }

    li {
      display: inline-block;
      width: 60px;
      height: 60px;
      transition: opacity var(--fast);

      &:hover {
        opacity: 0.75;
      }

      &:not(:last-of-type) {
        margin-right: 16px;
      }
    }

    div {
      margin-top: auto;

      @media (max-width: $tablet) {
        margin-top: 56px;
      }
    }
  }
}