@import "vars2";

.header {
  max-width: var(--container-width);
  padding: 0 var(--container-offset);
  margin: auto;
  overflow: hidden;

  @media (max-width: $laptop) {
    max-width: 100%;
    width: 100%;
  }

  &__box {
    position: absolute;
    top: 41px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    max-width: var(--container-width);
    width: 100%;

    @media (max-width: $laptop) {
      top: 15px;
    }

    @media (max-width: $tablet) {
      left: 50%;
      transform: translateX(-50%);
      width: initial;
    }
    
    @media (max-width: $mobile) {
      padding: 0 $mob-padding;
    }
  }
  
  &__logo {

    img {
      max-width: 250px;
  
      @media (max-width: $mobile) {
        max-width: 189px;
      }
    }
  }
  
  .nav {
    margin: 0 65px;

    @media (max-width: $laptop) {
      margin: 0 30px;
    }

    @media (max-width: $tablet) {
      display: none;
    }

    &__list {
      display: flex;
    }

    &__item {
      color: #fff;
      font-weight: 700;
      white-space: nowrap;
      transition: color var(--fast);

      @media (max-width: 1100px) {
        font-size: 13px;
      }

      &:hover {
        color: var(--accent-color);
      }
      
      &:not(:last-of-type) {
        margin-right: 20px;

        @media (max-width: $laptop) {
          margin-right: 10px;
        }
      }
    }
  }

  &__donate {
    display: grid;
    place-content: center;
    height: 40px;
    min-width: 200px;
    background: var(--accent-color);
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 13px;
    font-weight: 700;
    letter-spacing: 0.0622em;
    color: var(--text-color);
    border: 1px solid #FFCC66;
    border-radius: 30px;
    cursor: pointer;

    .support-page & {
      @media (max-width: $tablet) {
        display: none !important;
      }
    }

    &._mob {
      display: none;
      
      @media (max-width: $tablet) {
        position: fixed;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        display: grid;
        z-index: 9;
      }
    }

    &._desc {
      @media (max-width: $tablet) {
        display: none;
      }
    }

  }
}