@import "vars2";

.about {
  padding-bottom: 80px;

  @media (max-width: $tablet) {
    padding-bottom: 56px;
  }
  
  &__card {
    position: relative;
    margin-bottom: 60px;
    margin-top: -155px;
    padding: 52px 32px 0 32px;
    background: #fff;
    z-index: 1;
    border-radius: 8px;

    @media (max-width: $laptop) {
      margin-top: -100px;
    }

    @media (max-width: $tablet) {
      margin-top: 0;
      border-radius: 0;
    }

    @media (max-width: $mobile) {
      padding: 56px 0;
    }
  }
  
  &__title {
    font-size: 80px;
    line-height: 1.1;
    text-align: center;
    font-weight: 300;
    color: var(--text-color);

    @media (max-width: $laptop) {
      font-size: 64px;
    }

    @media (max-width: $tablet) {
      font-size: 32px;
      
    }

    @media (max-width: $mobile) {
      
    }

    b {
      font-weight: 900;
      color: #000;
      text-transform: uppercase;
    }
  }

  &__desc {
    padding-left: 30px;
    padding-bottom: 60px;
    color: #000;
    font-weight: 900;
    font-size: 34px;
    line-height: 1.5;

    @media (max-width: $tablet) {
      padding-bottom: 32px;
      padding-left: 0;      
    }

    @media (max-width: $mobile) {
      
    }
  }

  &__list {
    padding: 0 32px;

    @media (max-width: $laptop) {

    }

    @media (max-width: $tablet) {
      padding: 0;
      display: flex;
      flex-direction: column;
    }

    @media (max-width: $mobile) {

    }
  }

  &__item {
    display: inline-block;
    vertical-align: text-top;
    position: relative;
    padding: 0 10px;
    color: #000;
    font-size: 20px;
    line-height: 1.5;
    text-transform: lowercase;
    max-width: 25%;
    font-weight: 400;
    counter-increment: li; 

    @media (max-width: $laptop) {

    }

    @media (max-width: $tablet) {
      margin-left: auto;
      margin-right: auto;
    
      &:nth-of-type(1) {
        max-width: 196px;
        margin-bottom: 84px;
      }

      &:nth-of-type(2) {
        max-width: 260px;
        margin-bottom: 53px;
      }

      &:nth-of-type(3) {
        max-width: 280px;
        margin-bottom: 49px;
      }  

      &:nth-of-type(4) {
        max-width: 300px;
      } 
    }

    &:before {
      content: ''counter(li);
      position: absolute;
      top: -30px;
      left: 70px;
      color: var(--text-color);
      font-size: 100px;
      line-height: 1;
      opacity: 0.6;
      font-weight: 900;

      @media (max-width: $tablet) {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
      background: #000;
    }
  }
}